import { WordCloud, WordCloudProps } from '@esg/client/graphs-ui';
import { useInjectData } from './use-inject-data';

export function KeywordsPage() {
  const options = useInjectData<WordCloudProps>('wordcloud');

  return options ? (
    <WordCloud height="100vh" width="100vw" {...options} />
  ) : (
    <p>Loading...</p>
  );
}
