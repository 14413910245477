import d3LayoutCloud from 'd3-cloud';
import * as d3 from 'd3';
import { useCallback } from 'react';
import D3Wrapper, { D3WrapperProps } from '../d3-wrapper/d3-wrapper';

export interface WordCloudProps {
  color?: string;
  fontFamilty?: string;
  pathLogo?: string;
  data: Array<{
    groupName: string;
    argument: string;
    numberOfResponses: number;
  }>;
  width?: number | string;
  height?: number | string;
}

export function makeWordCloud(
  options: WordCloudProps
): D3WrapperProps['d3funciton'] {
  return (root) => {
    const { width, height } = root.getBoundingClientRect();
    const svg = d3.select(root);
    const widthGraph = width;
    const heightGraph = height - 40;

    svg.selectChild('g')?.remove();
    svg.selectChild('image')?.remove();

    const sizeRange = [
      d3.min(options.data, (d) => d.numberOfResponses),
      d3.max(options.data, (d) => d.numberOfResponses),
    ] as [number, number];

    const scaleSize = d3.scaleLinear(sizeRange, [11, 30]);

    if (options.pathLogo) {
      svg.append('image').attr('src');
    }

    const container = svg.append('g');

    function draw(words: Array<d3LayoutCloud.Word>) {
      container
        .attr(
          'transform',
          `translate(${layout.size()[0] / 2},${layout.size()[1] / 2 + 40})`
        )
        .selectAll('text')
        .data(words)
        .enter()
        .append('text')
        .style('font-size', (d) => {
          return d.size ? `${d.size}px` : '0px';
        })
        .style('fill', options.color || '#69b3a2')
        .attr('text-anchor', 'middle')
        .style('font-family', options.fontFamilty || 'Impact')
        .attr('transform', function (d) {
          return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
        })
        .text((d) => {
          return d.text || '';
        });
    }

    const layout = d3LayoutCloud()
      .size([widthGraph, heightGraph])
      .words(
        options.data.map(function (d) {
          return {
            text: d.argument,
            size: scaleSize(d.numberOfResponses),
          };
        })
      )
      .padding(2)
      .rotate(function () {
        return ~~(Math.random() * 2) * 90;
      })
      .fontSize((d) => {
        return d.size || 0;
      })
      .on('end', draw);

    // This is the naive implementation to solve this issue https://github.com/jasondavies/d3-cloud/issues/159
    let tryCount = 0;
    function drawGraph() {
      requestAnimationFrame(() => {
        tryCount++;
        container.selectAll('text')?.remove();
        layout.stop();
        layout.start();
        const countRenderedWords = container.selectAll('text').size();
        if (countRenderedWords < options.data.length && tryCount < 50) {
          drawGraph();
        } else if (tryCount >= 50) {
          console.error('Word cloud failed to render all the words');
        }
      });
    }

    drawGraph();

    return container;
  };
}

export function WordCloud(props: WordCloudProps) {
  const d3funciton = useCallback(() => makeWordCloud(props), [props]);
  return (
    <D3Wrapper
      d3funciton={d3funciton()}
      width={props.width}
      height={props.height}
    />
  );
}

export default WordCloud;
