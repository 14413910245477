import { SVGProps, useEffect, useRef } from 'react';
import { Selection } from 'd3';

export interface D3WrapperProps {
  d3funciton: (
    root: SVGSVGElement
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Selection<any, any, any, any>;
}

export function D3Wrapper({
  d3funciton,
  ...props
}: D3WrapperProps & SVGProps<SVGSVGElement>) {
  const svg = useRef<SVGSVGElement>(null);

  useEffect(() => {
    let pevElement: ReturnType<D3WrapperProps['d3funciton']>;
    function hadleResize() {
      if (svg.current) {
        pevElement?.remove();
        pevElement = d3funciton(svg.current);
      }
    }
    window.addEventListener('resize', hadleResize);
    return () => {
      window.removeEventListener('resize', hadleResize);
    };
  });

  useEffect(() => {
    if (svg.current) {
      const element = d3funciton(svg.current);
      return () => {
        element.remove();
      };
    }
    return undefined;
  }, [d3funciton]);

  return <svg width={'100%'} height={'100%'} {...props} ref={svg} />;
}

export default D3Wrapper;
