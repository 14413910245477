import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { KeywordsPage } from './keywords.page';
import { MaterialityPage } from './materiality.page';

export function App() {
  return (
    <Router>
      <Routes>
        <Route path="/wordcloud" element={<KeywordsPage />} />
        <Route path="/materiality" element={<MaterialityPage />} />
        <Route
          path="*"
          element={
            <p>
              To render the keywords graph go to{' '}
              <a href="/wordcloud">/wordcloud</a> and the materiality graph{' '}
              <a href="/materiality">/materiality</a>
              <h3>Documentation</h3>
              <p>
                There is a function exposed thorught the window object called
                `injectData`
              </p>
              <p>
                An example to provide data to the wordcloud graph:
                <pre lang="javascript">
                  {`window.injectData('wordcloud',
                    {
                      data: [
                        { groupName: "bello", argument: 'asdasd', numberOfResponses: 15 },
                        { groupName: "bello", argument: 'narui', numberOfResponses: 9 }\
                      ]
                    })`}
                </pre>
              </p>
              For the customization you can provide additional options other
              than the data attribute
              <pre lang="typescript">
                {`
                    color?: string;
                    fontFamilty?: string;
                    pathLogo?: string;
                    width?: number | string;
                    height?: number | string;
                  `}
              </pre>
              <p>
                An example to provide data to the materiality graph:
                <pre lang="javascript">
                  {`window.injectData('materiality',
                    {
                      center: [5,5],
                      data: [
                        {
                          questionNumber: '1',
                          questionDesc: 'desc1',
                          x: 4,
                          y: 5.2,
                        },
                        {
                          questionNumber: '2',
                          questionDesc: 'desc2',
                          x: 3,
                          y: 8.9,
                        },
                        {
                          questionNumber: '3',
                          questionDesc: 'desc3',
                          x: 5,
                          y: 10,
                        }
                      ]
                    })`}
                </pre>
                For the customization you can provide additional options other
                than the data attribute
                <pre lang="typescript">
                  {`
                    height?: number | string;
                    width?: number | string;
                    center: Array<number>;
                    date?: string;
                    background?: Array<string>;
                    texts?: Array<string>;
                  `}
                </pre>
              </p>
            </p>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
