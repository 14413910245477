import { useCallback } from 'react';
import * as d3 from 'd3';
import D3Wrapper, { D3WrapperProps } from '../d3-wrapper/d3-wrapper';

/* eslint-disable-next-line */
export interface MaterialityMapProps {
  height?: number | string;
  width?: number | string;
  center: Array<number>;
  date?: string;
  background?: Array<string>;
  texts?: Array<string>;
  logo?: HTMLImageElement;
  data: Array<{
    questionNumber: string;
    questionDesc: string;
    color?: string;
    x: number;
    y: number;
    radius?: number;
  }>;
}

export function makeMaterialityMapGraph(
  options: MaterialityMapProps
): D3WrapperProps['d3funciton'] {
  return (root) => {
    const sizes = root.getBoundingClientRect();
    const svg = d3.select(root);
    const margin = 0;
    const deviation = 2;
    const hoverColor = 'green';
    const heightGraph = sizes.height - 120;
    const widthGraph = sizes.width - 60;

    const minMaxX = [
      (d3.min(options.data.map((data) => data.x)) as number) - deviation,
      (d3.max(options.data.map((data) => data.x)) as number) + deviation,
    ] as [number, number];

    const minMaxY = [
      (d3.min(options.data.map((data) => data.y)) as number) - deviation,
      (d3.max(options.data.map((data) => data.y)) as number) + deviation,
    ] as [number, number];

    const xScale = d3.scaleLinear(minMaxX, [margin, widthGraph]);
    const yScale = d3.scaleLinear(minMaxY, [heightGraph, margin]);

    svg.select('g')?.remove();

    const container = svg.append('g').attr('transform', 'translate(40,80)');

    // circles
    const dots = container.selectAll('circle').data(options.data);
    dots
      .enter()
      .append('circle')
      .attr('data-target', (d, index) => 'target_' + d.questionNumber)
      .attr('fill', (d) => (d.color ? d.color : 'black'))
      .attr('stroke', (d) => (d.color ? d.color : 'black'))
      .attr('stroke-width', 0)
      .attr('cy', (d) => yScale(d.y))
      .attr('cx', (d) => xScale(d.x))
      .attr('r', (d) => (d.radius ? d.radius : 10))
      .on('mouseenter', function (event, d) {
        d3.select(`[data-target=target_${d.questionNumber}]`)
          .transition()
          .duration(200)
          .attr('fill', hoverColor)
          .attr('r', d.radius ? d.radius * 1.2 : 10 * 1.2)
          .style('cursor', 'pointer');

        container
          .append('text')
          .text(d.questionDesc)
          .attr('class', 'labeltext')
          .attr('x', xScale(d.x) - 10)
          .attr(
            'y',
            d.radius ? yScale(d.y) + (d.radius * 1.2 + 15) : yScale(d.y) + 25
          )
          .attr('fill', 'black')
          .attr('stroke', 'black')
          .attr('font-size', '14');
      })
      .on('mouseleave', function (event, d) {
        d3.select(`[data-target=target_${d.questionNumber}]`)
          .transition()
          .duration(200)
          .attr('fill', d.color ? d.color : 'black')
          .attr('r', d.radius ? d.radius : 10)
          .style('cursor', 'pointer');

        d3.select('.labeltext').remove();
      });

    // texts
    dots.selectAll('text').data(options.data);
    dots
      .enter()
      .append('text')
      .attr('fill', (d) => 'black')
      .attr('stroke', (d) => 'black')
      .style('text-align', 'center')
      .style(
        'text-shadow',
        '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff'
      )
      .text((d) => d.questionNumber && d.questionNumber)
      .attr('transform', (d) =>
        d.radius
          ? `translate(${xScale(d.x) - 4}, ${yScale(d.y) - d.radius - 5})`
          : `translate(${xScale(d.x) - 4}, ${yScale(d.y) - 15})`
      )
      .attr('font-size', 15);

    const widthFromCenterToEnd = xScale(minMaxX[1]) - xScale(options.center[0]);
    const height =
      heightGraph - (yScale(minMaxY[1]) + yScale(options.center[1]));
    container
      .append('rect')
      .attr('x', xScale(minMaxX[0]))
      .attr('y', yScale(minMaxY[1]))
      .attr('width', xScale(options.center[0]))
      .attr('height', yScale(options.center[1]))
      .attr(
        'fill',
        options.background
          ? options.background[0] !== ''
            ? options.background[0]
            : 'white'
          : 'white'
      );

    container
      .append('rect')
      .attr('x', xScale(minMaxX[0]))
      .attr('y', yScale(options.center[1]))
      .attr('width', xScale(options.center[0]))
      .attr('height', height)
      .attr(
        'fill',
        options.background
          ? options.background[1] !== ''
            ? options.background[1]
            : 'white'
          : 'white'
      );

    container
      .append('rect')
      .attr('x', xScale(options.center[0]))
      .attr('y', yScale(minMaxY[1]))
      .attr('width', widthFromCenterToEnd)
      .attr('height', yScale(options.center[1]))
      .attr(
        'fill',
        options.background
          ? options.background[2] !== ''
            ? options.background[2]
            : 'white'
          : 'white'
      );

    container
      .append('rect')
      .attr('x', xScale(options.center[0]))
      .attr('y', yScale(options.center[1]))
      .attr('width', widthFromCenterToEnd)
      .attr('height', height)
      .attr(
        'fill',
        options.background
          ? options.background[3] !== ''
            ? options.background[3]
            : 'white'
          : 'white'
      );

    // date
    const marginLeftRight = 10;
    const marginTopBottom = 20;
    if (options.date) {
      container
        .append('text')
        .attr('x', widthGraph - marginLeftRight)
        .attr('y', -30)
        .text(options.date)
        .attr('font-size', 18)
        .attr('text-anchor', 'end');
    }

    // image
    if (options.logo) {
      container
        .append('image')
        .attr('width', '200')
        .attr('height', '50')
        .style('float', 'left')
        .attr('x', marginLeftRight)
        .attr('y', yScale(minMaxY[1]) - marginTopBottom - 50)
        .attr('href', options.logo.src);
    }

    // axis labels
    container
      .append('text')
      .attr('class', 'x label')
      .attr('x', widthGraph / 2)
      .attr('y', heightGraph + 30)
      .text('Board')
      .attr('font-size', 12);

    container
      .append('text')
      .attr('class', 'y label')
      .attr('x', -(heightGraph / 2))
      .attr('y', -30)
      .attr('transform', 'rotate(-90)')
      .text('Stakeholder')
      .attr('font-size', 12);

    // dial texts
    if (options.texts && options.texts.length > 0) {
      // top-left
      if (options.texts[0]) {
        container
          .append('text')
          .attr('x', marginLeftRight)
          .attr('y', yScale(minMaxY[1]) + marginTopBottom)
          .text(options.texts[0])
          .style('text-align', 'right')
          .attr('font-size', 15);
      }
      // top-right
      if (options.texts[1]) {
        container
          .append('text')
          .attr('x', widthGraph - marginLeftRight)
          .attr('y', marginTopBottom)
          .text(options.texts[1])
          .attr('font-size', 15)
          .attr('text-anchor', 'end');
      }
      // bottom-left
      if (options.texts[2]) {
        container
          .append('text')
          .attr('x', marginLeftRight)
          .attr('y', yScale(minMaxY[0]) - marginTopBottom)
          .text(options.texts[2])
          .attr('font-size', 15);
      }
      // bottom-right
      if (options.texts[3]) {
        container
          .append('text')
          .attr('x', widthGraph - marginLeftRight)
          .attr('y', yScale(minMaxY[0]) - marginTopBottom)
          .text(options.texts[3])
          .attr('font-size', 15)
          .attr('text-anchor', 'end');
      }
    }

    // xaxis
    container
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0, ' + heightGraph + ')')
      .call(
        d3
          .axisBottom(xScale)
          .tickSizeInner(-heightGraph)
          .ticks(10)
          .tickFormat(function (d) {
            return d3.format('.1f')(Number(d));
          })
      );

    // yaxis
    container
      .append('g')
      .attr('class', 'y axis')
      .call(
        d3
          .axisLeft(yScale)
          .tickSizeInner(-widthGraph)
          .ticks(10)
          .tickFormat(function (d) {
            return d3.format('.1f')(Number(d));
          })
      );

    dots.raise();
    d3.selectAll('g').lower();
    d3.selectAll('rect').lower();

    return dots;
  };
}
export function MaterialityMap(props: MaterialityMapProps) {
  const d3funciton = useCallback(() => makeMaterialityMapGraph(props), [props]);
  return (
    <D3Wrapper
      d3funciton={d3funciton()}
      height={props.height}
      width={props.width}
    />
  );
}

export default MaterialityMap;
