import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ObservableStorage<Type = any> {
  private subscriptions = new Map<string, (value: Type) => void>();

  public set(key: string, value: Type) {
    const funct = this.subscriptions.get(key);
    if (funct) {
      funct(value);
    }
  }

  subscribe(key: string, callback: (value: Type) => void) {
    this.subscriptions.set(key, callback);
  }

  unsubscribe(key: string) {
    this.subscriptions.delete(key);
  }
}

const observableState = new ObservableStorage();

export function useInjectData<OfType>(key: string) {
  const [data, setData] = useState<OfType | undefined>();

  useEffect(() => {
    observableState.subscribe(key, (value: OfType) => {
      setData(value);
    });
    return () => {
      observableState.unsubscribe(key);
    };
  }, [key]);

  return data;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).injectData = (key: string, data: any) => {
  observableState.set(key, data);
};
